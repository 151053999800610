import { css } from "@emotion/react";
import Link from "next/link";

import { Typography, Row, Col, Divider } from "antd";
import { typography, colourCss } from "config/theme";
import { useFooter } from "lib/hooks/useFooter";

const styles = {
    rootContainer: css`
        background: #ebeefd;
    `,
    bodyContainer: css`
        max-width: 1070px;
        margin: auto;
        padding: 24px;
    `,
    headerTitle: css`
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
    `,
    divider: css`
        margin: 16px 0;
        background: #8c8c8c;
    `,
    imageIcon: css`
        margin: 0 5px;
        height: 18px;
        cursor: pointer;
    `,
};

const Footer = (): JSX.Element => {
    const footer = useFooter();
    return (
        <div css={styles.rootContainer}>
            <div css={styles.bodyContainer}>
                <Row gutter={[0, 16]}>
                    {footer.sections.map((section) => (
                        <Col key={section.title} xs={24} lg={6}>
                            <Row gutter={[0, 8]}>
                                <Col xs={24}>
                                    <Typography.Text
                                        strong
                                        css={[
                                            styles.headerTitle,
                                            colourCss.primary,
                                        ]}
                                    >
                                        {section.title}
                                    </Typography.Text>
                                </Col>
                                {section.links.map((link) => (
                                    <Col xs={24} key={link.path}>
                                        <Link href={link.path}>
                                            <a>
                                                <Typography.Text>
                                                    <span
                                                        css={[
                                                            typography.tinyContent,
                                                            colourCss.primary,
                                                        ]}
                                                    >
                                                        {link.name}
                                                    </span>
                                                </Typography.Text>
                                            </a>
                                        </Link>
                                    </Col>
                                ))}
                            </Row>
                            <Row>
                                <Col xs={24} lg={0}>
                                    <Divider css={styles.divider} />
                                </Col>
                            </Row>
                        </Col>
                    ))}
                </Row>
                <Divider css={styles.divider} />
                <div className="flex items-baseline justify-between gap-8">
                    <Typography.Text css={typography.tinyContent}>
                        {footer.copyright}
                    </Typography.Text>
                    <div className="grid flex-none grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-6">
                        {footer.social.map((fs, i) => (
                            <Link href={fs.path} key={i} passHref>
                                <img
                                    alt={fs.alt}
                                    src={fs.img}
                                    css={styles.imageIcon}
                                />
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
